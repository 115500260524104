<template>
  <div class="news-detail-wrapper">
    <div class="content-wrapper">
      <div class="card-box">
        <h4>{{detail.newsTitle}}</h4>
        <p class="label"><span>{{detail.newsPromulgator}}</span><span>{{detail.publishTime}}</span></p>
        <div class="content" v-html="detail.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api/index'
const { getCgNewDetail } = API

export default {
  name: 'newsDetail',
  data () {
    return {
      detail: {}
    }
  },
  activated () {
    this.getDiscloseDetail()
  },
  methods: {
    async getDiscloseDetail () {
      let loading = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true
      })

      let newsId = this.$route.params.id
      let res = await getCgNewDetail({ newsId })

      loading.clear()

      if (res.success) {
        this.detail = res.data
      } else {
        this.$toast(res.message)
      }
    }
  }
}
</script>

<style lang="scss">
  .news-detail-wrapper{
    .card-box{
      height: 100%;
      overflow-y: auto;
      h4{
        font-size: 36px;
        color: #333;
        margin-bottom: 12px;
        font-weight: bold;
        line-height: 1.5;
      }
      .label{
        font-size: 22px;
        color: #bbb;
        span:last-child{
          margin-left: 40px;
        }
      }
      .content{
        margin-top: 26px;
        font-size: 28px;
        color: #333;
        line-height: 1.5;
        img{
          max-width: calc(100% - 28px)!important;
        }
      }
    }
  }
</style>
